<div class="main-content">

  <div class="container-fluid">
    <app-progress-bar [progress]="progress" (progressUpdated)="onProgressUpdate($event)"></app-progress-bar>
    <!-- <div class="col-md-10"> <button>Back</button></div> -->
    <div *ngIf="!IsVideo; else elseBlock">
      <div class="row">
        <div class="col-md-12">
          <!-- <app-progress-bar [progress]="progress" (progressUpdated)="onProgressUpdate($event)"></app-progress-bar> -->
          <div class="card">
            <div class="card-header mb-1">
              <div class="row col-md-12">
                <div class="row col-md-8">
                  <div class="row col-md-2">

                    <button class="btn btn-editor btn-link ml-2" (click)="enablejobtoggleTooltip()"
                      data-tippy-content="Job Details" data-tippy-trigger="mouseenter focus" data-tippy-arrow="true"
                      data-tippy-placement="bottom" data-tippy-offset="[0, 5]">
                      <i class="material-symbols-outlined btn-editor-i">info</i>
                    </button>
                    <div *ngIf="isjobdataTooltipVisible" class="jobtooltip" [innerHTML]="jobDataContent"></div>
                  </div>
                  <div class="row col-md-8">
                    <h4 class="file-name" title="{{ NsJobId }}">NsJobId: {{ NsJobId }}</h4>
                  </div>
                  <!-- <div class="row col-md-8">
                                    <h4 class="file-name" title="{{ NcJobId }}">NcJobId: {{ NcJobId }}</h4>
                                  </div> -->
                </div>
                <div class="col-md-4 d-flex justify-content-end">
                  <label [hidden]="isEditingEnabled" class="labeleditdocumenttext">Viewing</label>

                  <button *ngIf="allowTranscriptEditing" class="btn btn-editor btn-link" (click)="enableEdit($event)"
                    data-tippy-content="Start Document Editing" data-tippy-trigger="mouseenter focus"
                    data-tippy-arrow="true" data-tippy-placement="bottom" data-tippy-offset="[0, 5]">
                    <i class="material-symbols-outlined btn-editor-i">edit_document</i>
                  </button>
                  <label [hidden]="!isEditingEnabled" class="labeleditdocumenttext">Editing</label>
                  <button [hidden]="!isEditingEnabled" class="btn btn-editor btn-link ml-2" (click)="savedoc($event)"
                    data-tippy-content="Save Changes" data-tippy-trigger="mouseenter focus" data-tippy-arrow="true"
                    data-tippy-placement="bottom" data-tippy-offset="[0, 5]">
                    <i class="material-symbols-outlined btn-editor-i">save</i>
                  </button>

                  <button [hidden]="!isEditingEnabled" class="btn btn-editor btn-link ml-2" (click)="saveNExit($event)"
                    data-tippy-content="Save & Format" data-tippy-trigger="mouseenter focus"
                    data-tippy-arrow="true" data-tippy-placement="bottom" data-tippy-offset="[0, 5]">
                    <i class="material-symbols-outlined btn-editor-i">move_group</i>
                  </button>

                  <button class="btn btn-editor btn-link ml-2" (click)="exitEditor($event)" data-tippy-content="Close"
                    data-tippy-trigger="mouseenter focus" data-tippy-arrow="true" data-tippy-placement="bottom"
                    data-tippy-offset="[0, 5]">
                    <i class="material-symbols-outlined btn-editor-i">close</i>
                  </button>

                  <app-color-picker *ngIf="isColorVisible" class="btn-color-selection" [formControl]="color"
                    data-tippy-content="Select Highlighter Color" data-tippy-trigger="mouseenter focus"
                    data-tippy-arrow="true" data-tippy-placement="bottom" data-tippy-offset="[0, 5]">
                  </app-color-picker>

                </div>
              </div>
              <hr class="hr-css" style="margin-top: 7px; margin-bottom: 0px;">
            </div>

            <div class="card-body card-editor fullEditor" tabindex="0">
              <!-- speaker button -->
              <!-- change speaker menu -->
              <!-- <mat-menu id="addSpeakerMenu" #addSpeakerMenu="matMenu" backdropClass="addspeaker-menu-backdrop"
                xPosition="after" yPosition="below">
                <button (click)="selectSpeaker(speaker,i)" *ngFor="let speaker of speakerList;let ind=index;"
                  mat-menu-item>
                  <i class="icon d-flex align-items-center justify-content-center viq-icon viq-user-line"></i>
                  {{speaker.name}}
                </button>
                <button (click)="menuTrigger.openMenu()" [id]="'matMenuTriggerBtn'+i" mat-menu-item
                  class="addspeaker-add-button">
                  <i class="viq-icon viq-plus-line"></i>
                  Add New Speaker
                </button>
              </mat-menu>
              <button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="addSpeakerMenu"
                class="addnewauthor-button position-absolute invisible" mat-button>
                Add New Speaker
              </button> -->
              <div id="froalaeditor" (mousedown)="onClick($event)" (click)="onClick($event)" [froalaEditor]="options"
                (froalaInit)="initializeEditor($event)" [(froalaModel)]="transcription">
              </div>
            </div>

          </div>
          <div *ngIf='IsFilePlay' style="display: flex; align-items: center; background-color: #f0f0f0;">
            <!-- <img src="../../../assets/img/Video-temp.jpg"/> -->
            <audio #audioPlayer controls class="audioClass" controlsList="nodownload" (timeupdate)="onTimeUpdate($event)" (play)="onAudioPlay($event)"
              (pause)="onAudioPause($event)" #audioRef>
              <source [src]="fileToPlay" type="audio/mp3">
              Your browser does not support the audio element.
            </audio>
            <span class="material-icons download-icon" style="cursor:pointer;position:relative;" title="Download" (click)="downloadImageClick()"> download </span>
           
          </div>
        
        </div>
      </div>
    </div>
    <ng-template #elseBlock>
      <div class="row">
        <div class="col-md-7">
          <div class="card">
            <div class="card-header mb-1">
              <div class="row col-md-12">
                <div class="row col-md-9">
                  <div class="row col-md-2">
                    <button class="btn btn-editor btn-link ml-2" (click)="enablejobtoggleTooltip()"
                      data-tippy-content="Job Details" data-tippy-trigger="mouseenter focus" data-tippy-arrow="true"
                      data-tippy-placement="bottom" data-tippy-offset="[0, 5]">
                      <i class="material-symbols-outlined btn-editor-i">info</i>
                    </button>
                    <div *ngIf="isjobdataTooltipVisible" class="jobtooltip" [innerHTML]="jobDataContent"></div>
                  </div>
                  <div class="row col-md-8">
                    <h4 class="file-name" title="{{ NsJobId }}">NsJobId: {{ NsJobId }}</h4>
                  </div>
                  <!-- <div class="row col-md-8">
                                    <h4 class="file-name" title="{{ NcJobId }}">NcJobId: {{ NcJobId }}</h4>
                                  </div> -->
                </div>
                <div class="col-md-3 d-flex justify-content-end" style="position: absolute; margin-left: 65%;">
                  <label [hidden]="isEditingEnabled" class="labeleditdocumenttext">Viewing</label>

                  <button *ngIf="allowTranscriptEditing" class="btn btn-editor btn-link" (click)="enableEdit($event)"
                    data-tippy-content="Start Document Editing" data-tippy-trigger="mouseenter focus"
                    data-tippy-arrow="true" data-tippy-placement="bottom" data-tippy-offset="[0, 5]">
                    <i class="material-symbols-outlined btn-editor-i">edit_document</i>
                  </button>
                  <label [hidden]="!isEditingEnabled" class="labeleditdocumenttext">Editing</label>
                  
                  <button [hidden]="!isEditingEnabled" class="btn btn-editor btn-link ml-2" (click)="savedoc($event)"
                    data-tippy-content="Save Changes" data-tippy-trigger="mouseenter focus" data-tippy-arrow="true"
                    data-tippy-placement="bottom" data-tippy-offset="[0, 5]">
                    <i class="material-symbols-outlined btn-editor-i">save</i>
                  </button>

                  <button [hidden]="!isEditingEnabled" class="btn btn-editor btn-link ml-2" (click)="saveNExit($event)"
                    data-tippy-content="Save & Format" data-tippy-trigger="mouseenter focus"
                    data-tippy-arrow="true" data-tippy-placement="bottom" data-tippy-offset="[0, 5]">
                    <i class="material-symbols-outlined btn-editor-i">move_group</i>
                  </button>

                  <button class="btn btn-editor btn-link ml-2" (click)="exitEditor($event)" data-tippy-content="Close"
                    data-tippy-trigger="mouseenter focus" data-tippy-arrow="true" data-tippy-placement="bottom"
                    data-tippy-offset="[0, 5]">
                    <i class="material-symbols-outlined btn-editor-i">close</i>
                  </button>

                  <app-color-picker *ngIf="isColorVisible" class="btn-color-selection" [formControl]="color" style="position: absolute;  margin-right: -45%;"
                    data-tippy-content="Select Highlighter Color" data-tippy-trigger="mouseenter focus"
                    data-tippy-arrow="true" data-tippy-placement="bottom" data-tippy-offset="[0, 5]">
                  </app-color-picker>

                </div>
              </div>
              <hr class="hr-css" style="margin-top: 7px; margin-bottom: 0px;">
            </div>
            <div class="card-body card-editor">
              <!-- <div [froalaEditor]="options" [(froalaModel)]="transcription"></div> -->
              <div id="froalaeditor" (mousedown)="onClick($event)" (click)="onClick($event)" [froalaEditor]="options"
                (froalaInit)="initializeEditor($event)" [(froalaModel)]="transcription"></div>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="card ">
            <div class="card-header mb-1">
              <h4 class="file-name">Play Video</h4>
              <hr class="hr-css" style="margin-top: 19px; margin-bottom: 0px;">
            </div>
            <div class="card-body card-editor" style="overflow: hidden;">
              <div *ngIf='IsFilePlay'>
                <video #audioPlayer class="audioClass" controlsList="nodownload" (timeupdate)="onTimeUpdate($event)" (play)="onAudioPlay($event)"
                  (pause)="onAudioPause($event)" style="height: 100%;margin-top: 9%;
                              width: 100%; " controls>
                  <source [src]="fileToPlay" type="audio/mp4">
                  Your browser does not support the audio element.
                </video>
                <!-- <mat-icon (click)="downloadImageClick()">cloud_download</mat-icon> -->
                <span class="material-icons download-icon" style="cursor:pointer;position:relative;" title="Video Download" (click)="downloadImageClick()"> download </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-content">
              <div class="row">
                <div class="col-lg-12">
                  <div *ngIf='!IsAudioPlay'>
                    <audio controls="controls" autoplay style="width: 100%;">
                      <source [src]="fileToPlay" type="audio/x-m4a">
                      Audio tag is unsupported in this browser
                    </audio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>

<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false" [autoClose]="true"
  [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>