// import { ViewChild } from "@angular/core";
import axios from "axios";
// import { jqxNotificationComponent } from "jqwidgets-ng/jqxnotification";
import { NgxSpinnerService } from "ngx-spinner";
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpProgressEvent } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { error } from "console";

@Injectable({
  providedIn: 'root'
})

export class FileAccessService {
   constructor() { }
    async downloadWithProgress(url: string, fileName: any, IsProgress) {
    return new Observable(observer => {
      axios({
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
        url: url,
        method: 'GET',
        responseType: 'blob',
        // onDownloadProgress: this.calcProgress.bind(this, url, IsProgress)// important
        onDownloadProgress: (progressEvent) => {
          const percentLoaded = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          observer.next({type: 'progress', value: percentLoaded});
        },
        onUploadProgress: (progressEvent) => {
          const percentLoaded = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          observer.next({type: 'progress', value: percentLoaded});
        }
      })
      .then(response => {
        observer.next({type: 'response', value: response});
        const url = window.URL.createObjectURL(new Blob([response.data]));
        //this.progress=0
        //console.log(event.value.data.size);
        //this.filesize = event.value.data.size;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
      });
    });
  }
  async uploadWithProgress(attachmenturl, filedetails) {
    return new Observable(observer => {
      axios.put(attachmenturl, filedetails, {
        onUploadProgress: (progressEvent) => {
          const percentLoaded = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          observer.next({type: 'progress', value: percentLoaded});
        }
      })
      .then(response => {
        observer.next({type: 'response', value: response});
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
      });
    });
  }
 }
