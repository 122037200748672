<h1 mat-dialog-title>
  <img class="img-icon mr-2" src="../../../assets/img/icon/column-chooser.png" />
  Choose Columns <button mat-dialog-close type="button" class="close pull-right"
    (click)='dialogClose()' aria-label="Close">&times;</button></h1>
<hr>
<div mat-dialog-content>
  <div class='col-md-12'>
    <jqxListBox #columnList
            (onCheckChange)="listOnSelect($event)"
            [source]="ShowColumns"
            [checkboxes]="true"
            [filterable]="true"
            [width]="'103%'"
            [height]="350">
    </jqxListBox>
    <br>
  </div>
</div>
<mat-dialog-actions align="end" style="margin-right: 20px; margin-bottom: 2px;">
  <button class='btn btn-viq-primary pull-right' (click)='saveChanges()'>Save</button>
  <button class='btn btn-viq-secondary pull-right ml-1' (click)='dialogClose()'>Cancel</button>
</mat-dialog-actions>
<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
    [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>