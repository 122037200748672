<h1 mat-dialog-title> <img class="img-icon mr-2" src="../../../assets/img/icon/onHoldandRemove.png" />
  OnHold/Remove:<button mat-dialog-close type="button" class="close pull-right" (click)='dialogClose()'
    aria-label="Close">&times;</button></h1>
<hr>
<div class="row" style="margin-left: 1px;">
  <mat-tab-group style="width: 97%;" (selectedTabChange)="tabClick($event)" [(selectedIndex)]="selectedIndex">
    <mat-tab label="OnHold" *ngIf="showingTabs">
      <div style="margin-left: 15px;margin-right: 15px;">
        <div class="row col-md-12" style="margin-top: 25px;">
          <!-- Display id -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">NSC Job ID</label></div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.id }}</label></div>
        </div>
        <div class="row col-md-12">
          <!-- Display nsJboId -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">NSW Job Id</label></div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.nsJboId }}</label>
          </div>
        </div>
        <div class="row col-md-12">
          <!-- Display nsJobNumber -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">NSW Job Number</label></div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.nsJobNumber
              }}</label></div>
        </div>
        <div class="row col-md-12">
          <!-- Display organizationName -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">Organization Name</label>
          </div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.organizationName
              }}</label></div>
        </div>
        <div class="row col-md-12">
          <!-- Display DepartmentName -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">Department Name</label></div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.customerName
              }}</label></div>
        </div>
        <div class="row col-md-12">
          <!-- Display status -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">Status</label></div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.status }}</label>
          </div>
        </div>
        <div class="col-md-12" style="margin-bottom: 25px;margin-top: 5px;">
          <p class="header-text-1" style="font-weight: 500; margin-bottom: 10px;">Comments</p>
          <jqxTextArea #commentsComponent [(ngModel)]="commentValue" (ngModelChange)="onCommentsChange()" [width]="'100%'" [height]="60" maxLength=950>
          </jqxTextArea>
        </div>
      </div>
      <button class='btn btn-viq-secondary pull-right ml-1' (click)='dialogClose()'>Cancel</button>
      <button class='btn btn-viq btn-viq-primary pull-right' (click)='HoldandRemoveDetails()' [disabled]="!isButtonEnabled">Hold</button>
    </mat-tab>
    <mat-tab style="margin-left: 10px;" label="Remove" *ngIf="!showingTabs">
      <div style="margin-left: 15px;margin-right: 15px;">
        <div class="row col-md-12" style="margin-top: 25px;">
          <!-- Display id -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">NSC Job ID</label></div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.id }}</label></div>
        </div>
        <div class="row col-md-12">
          <!-- Display nsJboId -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">NSW Job Id</label></div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.nsJboId }}</label>
          </div>
        </div>
        <div class="row col-md-12">
          <!-- Display nsJobNumber -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">NSW Job Number</label></div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.nsJobNumber
              }}</label></div>
        </div>
        <div class="row col-md-12">
          <!-- Display organizationName -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">Organization Name</label>
          </div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.organizationName
              }}</label></div>
        </div>
        <div class="row col-md-12">
          <!-- Display DepartmentName -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">Department Name</label></div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.customerName
              }}</label></div>
        </div>
        <div class="row col-md-12">
          <!-- Display status -->
          <div class="col-md-5" style="text-align: left;"><label class="text-form-note-l">Status</label></div>
          <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{ jobRows.status }}</label>
          </div>
        </div>
        <div class="col-md-12" style="margin-bottom: 25px;margin-top: 5px;">
          <p class="header-text-1" style="font-weight: 500; margin-bottom: 10px;">Comments</p>
          <jqxTextArea #commentsComponent [(ngModel)]="commentValue" (ngModelChange)="onCommentsChange()" [width]="'100%'" [height]="60" maxLength=950>
          </jqxTextArea>
        </div>
        <div class="row col-md-6" style="margin-bottom: 25px; margin-top: 5px; margin-left: 3px;">
          <mat-form-field class="example-full-width">
            <mat-label>Status of Job</mat-label>
            <input type="text" placeholder="Pick one" aria-label="Number" matInput [matAutocomplete]="auto"
              [value]="defaultOption"> <!-- Bind input value to defaultOption -->
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="StatusChangeEvent($event)">
              <mat-option *ngFor="let jobReleaseHoldStateOption of jobReleaseHoldStateOptions" [value]="jobReleaseHoldStateOption.label">{{jobReleaseHoldStateOption.label}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <button class='btn btn-viq-secondary pull-right ml-1' (click)='dialogClose()'>Cancel</button>
      <button class='btn btn-viq btn-viq-primary pull-right' (click)='HoldandRemoveDetails()' [disabled]="!isButtonEnabled">Remove</button>
    </mat-tab>
  </mat-tab-group>
</div>
<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false" [autoClose]="true"
  [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>