<!-- wraper-login-main -->
<div class="wraper-login-main position-relative overflow-hidden">
	<div class="container-fluid px-0">
		<!-- login-main -->
		<div class="login-main d-flex">
			<!-- login-main-form -->
			<div class="login-main-form d-flex flex-wrap justify-content-center position-relative z-index-2">
				<!-- login-main-form-curve -->
				<div class="login-main-form-curve position-absolute">
					<img src="../../assets/myviq/assets/images/login/Form-Right-Curve.png" alt="Form-Right-Curve">
				</div>
				<!-- login-main-form-holder -->
				<div class="login-main-form-holder w-100 h-100 d-flex flex-wrap justified-items-center">
					<div class="align-self-center w-100">
						<!-- login-main-form-heading -->
						<header class="login-main-form-heading section-heading text-center">
							<div class="brand-logo">
								<!-- <img src="../../assets/myviq/assets/images/VIQ-Logo.svg" alt="VIQ"> -->
								<img src="./assets/img/{{brandname}}/VIQ_Logo.png" alt="VIQ">
							</div>
							<h1 class="title">Welcome Back!</h1>
							<h2 class="subtitle">Login to your account</h2>
						</header>
						<!-- login-main-form-heading -->
						<!-- login-main-form-section -->
						<section class="login-main-form-section contact-form mx-auto">
							<form [formGroup]="loginForm">
								<!-- form-row -->
								<div class="form-row d-block mx-0">
									<!-- appearance="outline" add this for outline -->
									<mat-form-field >
										<mat-label for="userName">User Name <span>*</span></mat-label>
										<input #emailInput id="userName" formControlName="userName" matInput type="text" aria-describedby="email-error" autofocus>
										<mat-error id="email-error" *ngIf="loginForm.get('userName').errors && loginForm.get('userName').touched">
											<span *ngIf="loginForm.get('userName').errors.required">Please enter a valid userName ID.</span>
											<span *ngIf="loginForm.get('userName').errors.pattern"> Please enter a valid userName ID.</span>
										</mat-error>
									</mat-form-field>
								</div>
								<!-- form-row -->
								<!-- form-row -->
								<div class="form-row d-block mx-0">
									<!-- appearance="outline" add this for outline -->
									<mat-form-field >
										<mat-label for="password">Password <span>*</span></mat-label>
										<input id="password" maxlength="25" formControlName="password" matInput [type]="show ? 'text' : 'password'" aria-describedby="password-error">
										<div class="action-icon" matSuffix (click)="passwordToggle()">
											<i class="viq-icon {{show ? 'viq-eye-close' : 'viq-eye-open'}}"></i>
										</div>
										<mat-error id="password-error" *ngIf="loginForm.get('password').errors">
											<span *ngIf="loginForm.get('password').errors.required"> Please enter a valid password.</span>
										</mat-error>
									</mat-form-field>
								</div>
								<!-- form-row -->
								<!-- form-row -->
								<div class="form-row d-block mx-0 mb-4 text-center">
									<p class="login-help"><a [routerLink]="['/forgotpassword']" style="font-weight: inherit;color: #000;" [queryParams]="{params: 'forgot'}">Forgot Password?</a></p>
								</div>
								<!-- form-row -->
								<!-- form-row -->
								<div class="form-row d-block mx-0 mb-0">
									<button
									[disabled]='isLoginClicked'
									class="login-button w-100"
									mat-flat-button
									color="primary"
									(click)="login()" aria-pressed="true">
										Log in <div *ngIf="isLoginClicked" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
									</button>
									<!-- <button
									class="login-button w-100"
									mat-flat-button
									color="primary"
									(click)="openErrorDialog()">
										Log in
									</button> -->
								</div>
								<!-- form-row -->
								<!-- form-row -->
								<div class="form-row d-block mt-5 mx-0 text-center">
									<!-- <p class="login-trouble">Having trouble? <a [routerLink]="['/forgot-password']" [queryParams]="{params: 'admin'}">Contact Admin</a></p> -->
									<!-- <p class="login-help">Don’t have an account yet? <a routerLink="/sign-up">Sign up</a></p> -->
									<p> V{{ versionNumber }},   [{{ buildVersion }}]</p>
								</div>
								<!-- form-row -->
						  	</form>
						</section>
						<!-- login-main-form-section -->
					</div>
					<footer class="align-self-end w-100 mt-5">
						<!-- login-main-form-footer -->
						<div class="login-main-form-footer text-center">
							<!-- menu -->
							<ul class="menu">
								<!-- <li><a href="javascript:void()" (click)="openTOCDialog()">Terms &amp; Conditions, Privacy Policy</a></li> -->
								<!-- <li><a href="javascript:void()" (click)="openPrivacyDialog()">Privacy Policy</a></li> -->
								<!-- <li>Version: </li> -->
							</ul>
							<!-- menu -->
							<!-- formfooter-note -->
							<!-- <ul class="formfooter-note"><li>* This site is protected by reCAPTCHA</li></ul> -->
							<!-- formfooter-note -->
							<!-- copyright-note -->
							<ul class="copyright-note d-xl-none d-lg-none d-md-block d-sm-block d-block"><li>&copy; VIQ Solutions Inc. {{today | date: 'yyyy'}}. All Rights Reserved.</li></ul>
							<!-- copyright-note -->
						</div>
						<!-- login-main-form-footer -->
					</footer>
				</div>
				<!-- login-main-form-holder -->
			</div>
			<!-- login-main-form -->
			<!-- login-main-poster -->
			<section class="login-main-poster flex-fill d-xl-flex d-lg-flex d-md-none d-sm-none d-none flex-wrap justify-content-center position-relative z-index-1">
				<div class="align-self-center w-100 py-5">
					<!-- login-main-poster-heading -->
					<div class="login-main-poster-heading section-heading text-center">
						<div class="poster-image mx-auto mb-5">
							<img src="../../assets/myviq/assets/images/login/Login-Poster.png" alt="Login-Poster">
						</div>
						<h3 class="title mx-auto">Securely speed the capture, creation, and management of large volumes of information</h3>
					</div>
					<!-- login-main-poster-heading -->
				</div>
				<!-- login-main-poster-footer -->
				<div class="login-main-poster-footer position-absolute w-100 text-center">
					<!-- copyright-note -->
					<ul class="copyright-note"><li>&copy; VIQ Solutions Inc. {{today | date: 'yyyy'}}. All Rights Reserved.</li></ul>
					<!-- copyright-note -->
				</div>
				<!-- login-main-poster-footer -->
			</section>
			<!-- login-main-poster -->
		</div>
		<!-- login-main -->
	</div>
</div>
<!-- wraper-login-main -->
<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
    [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>
<jqxLoader #jqxLoader           
           [imagePosition]="'top'"
           [width]="100"
           [height]="60">
</jqxLoader>
