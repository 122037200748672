import { BackendService } from './../services/backend.service';
import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { bool } from 'aws-sdk/clients/signer';

declare var tippy: any;
declare const $: any;
let isAdmin = "";

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Admin Menu Items
export const ADMINROUTES: RouteInfo[] =
    [
        {
            path: '/admin/job-list',
            title: 'Job List View',
            type: 'link',
            icontype: 'ballot'
            // icontype: 'home'
        },
        {
            path: '/admin/upload-job',
            title: 'Upload/Record Jobs',
            type: 'link',
            icontype: 'file_upload'
        },
        {
            path: '/admin/adminpages',
            title: 'Administrator',
            type: 'sub',
            icontype: 'admin_panel_settings',
            collapse: 'adminpages',
            children: [
                { path: 'user', title: 'Users', ab: 'people_outline' },
                { path: 'tenant', title: 'Tenants', ab: 'contacts' },
                { path: 'organization', title: 'Organizations', ab: 'corporate_fare' },
                { path: 'department', title: 'Departments', ab: 'recent_actors' },
                { path: 'formtype', title: 'FormType', ab: 'vertical_split' },
                { path: 'connectors', title: 'Connectors', ab: 'cable' },
                { path: 'audit', title: 'Audit', ab: 'description' },
                { path: 'downtime', title: 'Downtime Notification', ab: 'schedule' }  
            ]
        },
        {
            path: '/admin/support',
            title: 'Support',
            type: 'link',
            icontype: 'help_outline'
        },
        {
            path: '/admin/user-profile',
            title: 'My Profile',
            type: 'link',
            icontype: 'manage_accounts'
        }
    ];
//Tenant Admin and Customer Admin 
// export const SUBADMINROUTES: RouteInfo[] =
//     [
//         {
//             path: '/admin/job-list',
//             title: 'Job List View',
//             type: 'link',
//             icontype: 'ballot'
//         },
//         {
//             path: '/admin/upload-job',
//             title: 'Upload/Record Jobs',
//             type: 'link',
//             icontype: 'file_upload'
//         },
//         {
//             path: '/admin/adminpages',
//             title: 'Administrator',
//             type: 'sub',
//             icontype: 'admin_panel_settings',
//             collapse: 'adminpages',
//             children: [
//                 { path: 'user', title: 'Users', ab: 'people_outline' },
//                 { path: 'tenant', title: 'Tenants', ab: 'contacts' },
//                 { path: 'organization', title: 'Organizations', ab: 'corporate_fare' },
//                 { path: 'department', title: 'Departments', ab: 'recent_actors' },
//                 { path: 'formtype', title: 'FormType', ab: 'vertical_split' },
//                 // {path:'connectors',title:'Connectors',ab:'group'}
//                 //{ path: 'support', title: 'Support', ab: 'group' }
//                 { path: 'audit', title: 'Audit', ab: 'description' }
//             ]
//         },
//         {
//             path: '/admin/support',
//             title: 'Support',
//             type: 'link',
//             icontype: 'help_outline'
//         },
//         {
//             path: '/admin/user-profile',
//             title: 'My Profile',
//             type: 'link',
//             icontype: 'manage_accounts'
//         }
//     ];
// //Dept Admin 
// export const DEPTADMINROUTES: RouteInfo[] =
//     [
//         {
//             path: '/admin/job-list',
//             title: 'Job List View',
//             type: 'link',
//             icontype: 'ballot'
//         },
//         {
//             path: '/admin/upload-job',
//             title: 'Upload/Record Jobs',
//             type: 'link',
//             icontype: 'file_upload'
//         },
//         {
//             path: '/admin/adminpages',
//             title: 'Administrator',
//             type: 'sub',
//             icontype: 'admin_panel_settings',
//             collapse: 'adminpages',
//             children: [
//                 { path: 'user', title: 'Users', ab: 'people_outline' },
//                 { path: 'audit', title: 'Audit', ab: 'description' }
//             ]
//         },
//         {
//             path: '/admin/support',
//             title: 'Support',
//             type: 'link',
//             icontype: 'help_outline'
//         },
//         {
//             path: '/admin/user-profile',
//             title: 'My Profile',
//             type: 'link',
//             icontype: 'manage_accounts'
//         }
//     ];

// //Normal User Menu
// export const ROUTES: RouteInfo[] =
//     [
//         {
//             path: '/admin/job-list',
//             title: 'Job List View',
//             type: 'link',
//             icontype: 'ballot'
//         },
//         {
//             path: '/admin/upload-job',
//             title: 'Upload/Record Jobs',
//             type: 'link',
//             icontype: 'file_upload'
//         },
//         {
//             path: '/admin/support',
//             title: 'Support',
//             type: 'link',
//             icontype: 'help_outline'
//         },
//         {
//             path: '/admin/user-profile',
//             title: 'My Profile',
//             type: 'link',
//             icontype: 'manage_accounts'
//         }
//     ];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    brandname = localStorage.getItem('brand');
    bgimage: string;

    ps: any;

    constructor(private backendService: BackendService) { }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return false;
    };
    removePaths = (routes: RouteInfo[], pathsToRemove: string[]) => {
        for (const route of routes) {
            if (route.children) {
                route.children = route.children.filter(child => !pathsToRemove.includes(child.path));
            }
        }
    };
    
    async getMenuItemsByUser(userName) {
        const isAdmin = localStorage.getItem('user_isadmin');
        const roleName = localStorage.getItem('user_roleName');
        // Create a deep copy of ADMINROUTES to avoid modifying the original array
        let routesCopy = JSON.parse(JSON.stringify(ADMINROUTES));
    
        if ((roleName == 'TenantAdmin' || roleName == 'CustomerAdmin') && isAdmin == "true") {
            const pathsToRemove: string[] = ['downtime','connectors'];
            this.removePaths(routesCopy, pathsToRemove);
            this.menuItems = routesCopy;
        } else if (roleName == 'Author' || roleName == 'Uploader') {
            const pathsToRemove: string[] = ['/admin/adminpages'];
            this.menuItems = routesCopy.filter(route => !pathsToRemove.includes(route.path));
        } else if (roleName == 'DepartmentAdmin') {
            localStorage.setItem('user_isadmin', "true");
            const pathsToRemove: string[] = ['tenant', 'organization', 'department','downtime', 'formtype', 'connectors'];
            this.removePaths(routesCopy, pathsToRemove);
            this.menuItems = routesCopy;
        }
        else if (roleName == 'HostAdmin') {
            const pathsToRemove: string[] = ['downtime'];
            this.removePaths(routesCopy, pathsToRemove);
            this.menuItems = routesCopy;
        } else {
            this.menuItems = routesCopy;
        }
    }


    ngOnInit() {

        isAdmin = "";
        // setTimeout(() => {
        this.getMenuItemsByUser(localStorage.getItem('user_attribute'));
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
        //}, 3000);

    }
    ngAfterViewInit(): void {
        // TIPPY JS.
        tippy('[data-tippy-content]');
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
