import { ChangePasswordComponent } from './change-password/change-password.component';
import { BackendService } from './../../services/backend.service';
import { Component, OnInit, Renderer2, ViewChild, ElementRef, Directive, Input } from '@angular/core';
import { ADMINROUTES } from '../.././sidebar/sidebar.component';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators'
import { NgxSpinnerService } from 'ngx-spinner';
import { FileAccessService } from 'src/app/services/fileaccess.service';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare var $: any;
let username = "";
let userRole = "";
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
    dialogRef: MatDialogRef<ChangePasswordComponent>;
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private _router: Subscription;
    public isVisible:boolean=true;
    @ViewChild('app-navbar-cmp', { static: false }) button: any;
    @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    constructor(
        location: Location,
        private renderer: Renderer2,
        private element: ElementRef,
        private router: Router,
        private backendService: BackendService,
        public dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private fileacces: FileAccessService) {
            
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }
    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];
        const nsc_logo = document.getElementsByClassName('nsc-logo')[0];
        const brand_logo = document.getElementsByClassName('brand-logo')[0];
        const viq_logo = document.getElementsByClassName('viq-logo')[0];
        const viq_logo_1 = document.getElementsByClassName('viq-logo')[1];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;
            viq_logo.classList.remove('show-logo');
            viq_logo.classList.add('hide-logo');
            if (viq_logo_1 !== undefined) {
                viq_logo_1.classList.remove('show-logo');
                viq_logo_1.classList.add('hide-logo');

                nsc_logo.classList.remove('hide-logo');
                nsc_logo.classList.add('show-logo');
            }
            console.log("brand_logo", brand_logo);
            if (brand_logo !== undefined) {
                brand_logo.classList.remove('hide-logo');
                brand_logo.classList.add('show-logo');
            }
        }
        else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');
                misc.sidebar_mini_active = true;
                viq_logo.classList.remove('hide-logo');
                viq_logo.classList.add('show-logo');
                if (viq_logo_1 !== undefined) {
                    viq_logo_1.classList.remove('hide-logo');
                    viq_logo_1.classList.add('show-logo');

                    nsc_logo.classList.remove('show-logo');
                    nsc_logo.classList.add('hide-logo');
                }
                if (brand_logo !== undefined) {
                    brand_logo.classList.remove('show-logo');
                    brand_logo.classList.add('hide-logo');
                }

            }, 10);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }
    hideSidebar() {
        const body = document.getElementsByTagName('body')[0];
        const sidebar = document.getElementsByClassName('sidebar')[0];

        if (misc.hide_sidebar_active === true) {
            setTimeout(function () {
                body.classList.remove('hide-sidebar');
                misc.hide_sidebar_active = false;
            }, 300);
            setTimeout(function () {
                sidebar.classList.remove('animation');
            }, 600);
            sidebar.classList.add('animation');

        } else {
            setTimeout(function () {
                body.classList.add('hide-sidebar');
                // $('.sidebar').addClass('animation');
                misc.hide_sidebar_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    ngOnInit() {
        var Isidpuser = localStorage.getItem('idpuser')
        if(Isidpuser == "true")
        {
            this.isVisible=false
        }
        misc.sidebar_mini_active = false;
        this.minimizeSidebar();
        this.listTitles = ADMINROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        // console.log("body", body);
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

        // if (body.classList.contains('sidebar-mini')) {
        //     misc.sidebar_mini_active = true;
        // }
        // if (body.classList.contains('hide-sidebar')) {
        //     misc.hide_sidebar_active = true;
        // }
        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.sidebarClose();

            const $layer = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }
        });

    }
    onResize(event) {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }
    sidebarOpen() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function () {
            $toggle.classList.add('toggled');
        }, 430);

        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');


        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        } else if (body.classList.contains('off-canvas-sidebar')) {
            document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }

        setTimeout(function () {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = function () { //asign a function
            body.classList.remove('nav-open');
            this.mobile_menu_visible = 0;
            this.sidebarVisible = false;

            $layer.classList.remove('visible');
            setTimeout(function () {
                $layer.remove();
                $toggle.classList.remove('toggled');
            }, 400);
        }.bind(this);

        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
        this.sidebarVisible = true;
    };
    sidebarClose() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        setTimeout(function () {
            if ($toggle) {
                $toggle.classList.remove('toggled');
            }

        }, 400);

        this.mobile_menu_visible = 0;
    };
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }
    getUsername() {
        username = localStorage.getItem('userName');
        return username;
    }

    getUserRole() {
        userRole = localStorage.getItem('user_roleName');
        return userRole;

    }

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        for (let i = 0; i < this.listTitles.length; i++) {

            if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
                return this.listTitles[i].title;
            }
            else if (this.listTitles[i].type === "sub") {
                for (let j = 0; j < this.listTitles[i].children.length; j++) {
                    let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
                    // console.log(subtitle)
                    // console.log(titlee)
                    if (subtitle === titlee) {
                        return this.listTitles[i].children[j].title;
                    }
                }
            }
        }
        // return 'Job List';
    }
    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }

    logout() {
        var curuserName = localStorage.getItem('userName')
        this.backendService.userAuditLoggedoff(curuserName).subscribe(
            {
                next: (value) => {
                    // console.log("value", value);
                },
                error: (err: any) => { console.log("err", err); },
            }
        );
        this.dialog.closeAll();
        this.backendService.isLoggedIn = false;
        this.backendService.setUserLoggedIn(false);
        this.spinner.hide();
    }

    openDialog(): void {
        if (this.dialogRef == null) {
            this.dialogRef = this.dialog.open(ChangePasswordComponent, {
                width: '35%',
                data: {},
                disableClose: true
            });
            this.dialogRef.afterClosed().subscribe(result => {
                this.dialogRef = null;
            });
        }
    }
    sendNotification(message, messageType) {
        this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
        this.msgNotification.template(messageType);
        this.msgNotification.open();
      }
      downloadReleaseNotes() {
        this.spinner.show();
        this.backendService.getReleaseNotesURL().subscribe(
          async (res: any) => {
            if (res.success) {
              // Attempt to download the file using the presigned URL
              this.fileacces.ReleaseNotesFileDownload(res.data)
                .then(response => {
                  if (response.status === 200 && response.data.size > 0) {
                    this.sendNotification("Downloaded release notes successfully", 'success');
                  } else {
                    this.sendNotification("No release notes found in S3", 'error');
                  }
                })
                .catch(error => {
                  console.error('Download Failed:', error);
                  this.sendNotification("Download release notes failed", 'error');
                })
                .finally(() => {
                  this.spinner.hide();
                });
            } else {
              this.sendNotification("Unable to download release notes", 'error');
              this.spinner.hide();
            }
            
          },
          err => {
            console.log(err);
            this.sendNotification("Unable to download release notes", 'error');
            this.spinner.hide();
          }
        );
      }
}

