import { Injectable, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpBackend } from '@angular/common/http';

import { environment } from "src/environments/environment";
import { UserConfigInputModel, pageParamsDto, LoggingModel, FileToUpload, JobCreateInputDto, OrganizationsDto, DepartmentsDto, TenantsDto, userStatusModel, transcriptionrequest } from '../models/apiRequestModels';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { Subject, Observable } from 'rxjs';
import { DateTime } from 'aws-sdk/clients/devicefarm';
import { DatePipe } from '@angular/common';
import { CognitoCallback, CognitoUtil, Callback } from "./cognito.service";
import { Router } from '@angular/router';

///AWS Environment setup

//  var AWS = require("aws-sdk");
//  let awsConfig = {
//    "region": environment.region,
//    "endpoint": environment.dynamoDBEndPoint,
//    "accessKeyId": environment.accessKeyId, "secretAccessKey": environment.secretAccessKey
//  };
//  AWS.config.update(awsConfig);
//  let docClient = new AWS.DynamoDB.DocumentClient();

@Injectable({
  providedIn: 'root'
})

export class BackendService {
  private userLoggedIn = new Subject<boolean>();

  userConfigInputModel: UserConfigInputModel[];
  isLoggedIn: boolean = false;
  userName: string = '';
  notificationRef: jqxNotificationComponent;
  pageParams: pageParamsDto;
  logModel: LoggingModel;
  orgName: string = '';
  deptName: string = '';
  tenantName: string = '';
  formTypeName: string = '';
  conName: string = '';
  userStatusModel: userStatusModel;
  nsapiUrl = environment.nswbaseUrls;
  private httpClient: HttpClient;
  private httpnswClient: HttpClient;
  constructor(
    private router: Router,
    private http: HttpClient,
    private httpBackend: HttpBackend,
    private datePipe: DatePipe,
    private httpnsw: HttpClient,
  ) {
    this.userLoggedIn.next(false);
    this.httpClient = new HttpClient(httpBackend);
    this.httpnswClient = new HttpClient(httpBackend);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  CheckIsUserLoggedIn() {
    var iSuserloggedIN = localStorage.getItem('userloggedIN')
    if (iSuserloggedIN == "false") {
      this.setUserLoggedIn(false);
      return false;
    }
    return true;
  }
  netscribeLogin() {
    let reqUrl = "/api/Account/Authenticate";
    console.info({
      'tenancyName': 'DH_Test',
      'usernameOrEmailAddress': 'myviqdev',
      'password': '"Viq2023!'
    });
    return this.httpnsw.post(this.nsapiUrl + reqUrl, { 'tenancyName': 'DH_Test', 'usernameOrEmailAddress': 'myviqdev', 'password': 'Viq2023!' });
  }
  GetLastDocumentVersionContent(nsJobId: number, nswauthtoken: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + nswauthtoken,
    });
    let reqUrl = "/api/services/app/job/GetLastDocumentVersionContent";
    return this.httpnsw.post(this.nsapiUrl + reqUrl, { 'id': nsJobId }, { headers });
  }
  PostAndFormatHtmlFileForExistingJob(nsJobId: number, nswauthtoken: string, htmlFile: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + nswauthtoken,
    });
    let reqUrl = "/api/services/app/connector/PostAndFormatHtmlFileForExistingJob";
    return this.httpnsw.post(this.nsapiUrl + reqUrl, { 'jobId': nsJobId, 'htmlFile': htmlFile }, { headers });
  }
  myviqPostAndFormatHtmlFileForExistingJob(jobid: string, transcript: string, isFormattable: boolean) {
    return this.http.post(this.baseURL + '/job/postandformathtmlfileforexistingjob', { 'jobid': jobid, 'transcript': transcript, 'isFormattable': isFormattable });
  }
  myviqGetLastDocumentVersionContent(jobid: string) {
    return this.http.post(this.baseURL + '/job/getlastdocumentversion', { 'jobid': jobid });
  }
  getAudioVideoEdit(nsJobId: Number, ncJobId: string) {
    return this.http.post(this.baseURL + '/job/downloadaudio', { 'jobId': ncJobId });
  }
  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
    localStorage.setItem('userloggedIN', userLoggedIn.toString());
    var curpage = localStorage.getItem('page');

    if (curpage == 'nclanding') {
      localStorage.setItem('idpuser', 'true');
    }
    else if (curpage == 'login') {
      localStorage.setItem('idpuser', 'false');
    }

    if (!userLoggedIn) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshtoken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user_attribute');
      localStorage.removeItem('user_isadmin');
      localStorage.removeItem('id');
      localStorage.removeItem('userName');
      localStorage.removeItem('user_roleName');
      localStorage.removeItem('user_jobColumnsList');
      localStorage.removeItem('tenantId');
      localStorage.removeItem('user_roleName');
      localStorage.removeItem('user_jobColumnsOrder');
      localStorage.removeItem('user_customStartdate');
      localStorage.removeItem('user_customEnddate');
      localStorage.removeItem('user_dateRange');
      localStorage.removeItem('isMFA');
      localStorage.removeItem('phone_number_verified');
      localStorage.removeItem('estimationMessage');
      localStorage.removeItem('isEstimateEnable');
      localStorage.removeItem('CustomerNotesEnable');
      var idpuser = localStorage.getItem('idpuser');
      console.log("idpuser: ", idpuser)
      if (idpuser == "true") {
        localStorage.removeItem('idpemail');
        this.router.navigateByUrl('/nclanding');
      }
      else {
        this.router.navigateByUrl('/');
      }
    }
  }

  public userAuditLoggedoff(username: string) {
    return this.http.post(this.baseURL + '/authenticate/userlogoff', { 'userName': username });
  }
  public getJobAuditDetails(jobId: string){
    return this.http.post(this.baseURL + '/audit/getjobauditdetails', { 'jobId': jobId });

  }
  checkUserTokenExpired(token: string) {
    const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))
    var exp = jwtPayload.exp * 1000;
    if (Date.now() >= jwtPayload.exp * 1000) {
      console.log("token Expired");
      return true;
    }
    else {
      return false;
    }
  }
  baseURL = environment.endpoint;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  getJSONResponse(value: any) {
    return JSON.parse(JSON.stringify(value));
  }
  public getHeaderOptions(token: any) {
    let httpHeaders = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    return httpHeaders;
  }
  public authnticate(username: string, password: string) {
    return this.http.post(this.baseURL + '/authenticate/userlogin', { 'userName': username, 'password': password });
  }
  public gettokenwithrefresh(username: string, refreshToken: string) {
    return this.httpClient.post(this.baseURL + '/authenticate/refreshtoken', { 'userName': username, 'refreshToken': refreshToken });
  }
  public isfileexist(checkFileNameRequest: string) {
    return this.http.post(this.baseURL + '/job/checkaudiofilename', { 'fileName': checkFileNameRequest });
  }

  public getTermsAndCondition() {
    return this.http.get('../../assets/resource/tnc.json');
  }
  public getTermsAndConditionByTenant(tenantId: string) {
    return this.http.post(this.baseURL + '/tenant/gettenanttnc', { 'TenantID': tenantId });
  }
  public updateusertnc(username: string, id: string, isTNC: boolean) {
    return this.http.post(this.baseURL + '/authenticate/updateusertnc', { 'userName': username, 'id': id, 'isTNC': isTNC });
  }
  public jobList(jobStatus: boolean) {
    return this.http.post(this.baseURL + '/job/statuslist', { 'jobStatus': jobStatus });
  }
  //////Job list with start and end Dates
  public jobsByDate(endDate: DateTime, jobStatus: boolean, startDate: DateTime) {
    return this.http.post(this.baseURL + '/job/jobsbydate', { 'endDate': startDate, 'jobStatus': jobStatus, 'startDate': endDate });
  }
  public allJobsByDate(endDate: DateTime, startDate: DateTime) {
    return this.http.post(this.baseURL + "/job/alljobsbydate", { 'endDate': startDate, 'startDate': endDate });
  }
  /////Job List with dates and pagination
  public jobsByDateAndPagination(endDate: DateTime, jobStatus: boolean, startDate: DateTime, pageno: string) {

    this.pageParams = new pageParamsDto();
    this.pageParams.pageSize = "1000";
    this.pageParams.pageNumber = pageno;
    return this.http.post(this.baseURL + '/job/jobsbypagestatus', { 'endDate': startDate, 'jobStatus': jobStatus, 'startDate': endDate, 'pageParamsDto': this.pageParams });
  }
  public getConnectors() {
    return this.http.get(this.baseURL + '/connectors/connectorslist');
  }
  public allJobsByDateAndPagination(endDate: DateTime, startDate: DateTime, pageno: string) {

    this.pageParams = new pageParamsDto();
    this.pageParams.pageSize = "1000";
    this.pageParams.pageNumber = pageno;
    return this.http.post(this.baseURL + '/job/jobsbypage', { 'endDate': startDate, 'startDate': endDate, 'pageParamsDto': this.pageParams });
  }
  /////Job List Excel export with dates and pagination
  public jobstatusexcelexport(endDate: DateTime, jobStatus: boolean, startDate: DateTime, pageno: string, timeZoneOffset: number) {
    this.pageParams = new pageParamsDto();
    this.pageParams.pageSize = "1000";
    this.pageParams.pageNumber = pageno;
    console.log("timeZoneOffset :::", timeZoneOffset);
    return this.http.post(this.baseURL + '/job/jobstatusexcelexport', { 'endDate': startDate, 'jobStatus': jobStatus, 'startDate': endDate, 'pageParamsDto': this.pageParams, 'timeZoneOffset': timeZoneOffset });
  }
  //Sql DB Excel Export
  public alljobsexcelexport(endDate: DateTime, showStatus: string, startDate: DateTime, pageno: string, timeZoneOffset: number) {

    this.pageParams = new pageParamsDto();
    this.pageParams.pageSize = "1000";
    this.pageParams.pageNumber = pageno;
    return this.http.post(this.baseURL + '/job/alljobsexcelexport', { 'endDate': startDate, 'showStatus': showStatus, 'startDate': endDate, 'pageParamsDto': this.pageParams, 'timeZoneOffset': timeZoneOffset });
  }
  public getDownloadURLByJobId(jobId: string, isDraft: boolean) {
    return this.http.post(this.baseURL + '/job/downloadmultipledocs', { 'jobId': jobId, 'isDraft': isDraft });
  }
  public getAudioDownloadURLByJobId(jobId: string) {
    return this.http.post(this.baseURL + '/job/downloadaudio', { 'jobId': jobId });
  }
  public getAttachmentDownloadURLByJobId(jobId: string) {
    return this.http.post(this.baseURL + '/job/downloadattachments', { 'jobId': jobId });
  }
  public getAllJobs() {
    return this.http.post(this.baseURL + "/job/list", {});
  }
  public updateShowStatus(jobId: string, jobStatus: boolean) {
    return this.http.post(this.baseURL + '/job/updateshowstatus', { 'jobId': jobId, 'jobStatus': jobStatus });
  }
  public getFormTypeValues() {
    return this.http.post(this.baseURL + '/formtypes/list', {})
  }
  public getDepartmentFormTypeValues(departmentId) {
    return this.http.post(this.baseURL + '/formtypes/departmentformtypes', { 'departmentId': departmentId })
  }
  public getDepartments() {
    return this.http.post(this.baseURL + '/department/list', {});
  }
  public getAuthors(customerId, deptExtId) {
    return this.http.post(this.baseURL + '/authors/list', { 'customerID': customerId, 'deptExtId': deptExtId });
  }
  public getAuthorsByDeptExtId(deptExtId) {
    return this.http.post(this.baseURL + '/authors/listbydeptextid', { 'deptExtId': deptExtId });
  }

  public getFormTypeDetails(formTypeId) {
    return this.http.post(this.baseURL + '/formtypes/detail', { 'formTypeId': formTypeId });
  }

  public getFormFieldTypeDetails(formTypeId) {
    return this.http.post(this.baseURL + '/formtypes/detail', { 'formTypeId': formTypeId });
  }
  public createJob(fileName: string, formTypeId: string, formData: object, departmentId: string, authorId: string, authorExtId: string, authorKey: string, referenceId: string, callbackUrl: string, file: FileToUpload[], audioFiles: FileToUpload[]) {
    return this.http.post(this.baseURL + '/job/create', { 'fileName': fileName, 'formTypeId': formTypeId, 'formData': formData, 'departmentId': departmentId, 'authorId': authorId, 'authorExtId': authorExtId, 'authorKey': authorKey, 'referenceId': null, 'callbackUrl': null, 'attachmentFiles': file, 'audioFiles': audioFiles });
  }
  public MultiJobCreate(jobCreateInputDto: JobCreateInputDto[]) {
    console.log("Multijobcreate model ", jobCreateInputDto);
    return this.http.post(this.baseURL + '/job/multijobcreate', jobCreateInputDto);
  }
  public changePassword(data) {
    return this.http.post(this.baseURL + '/authenticate/userchangepassword', data);
  }
  public firstTimeChangePassword(data) {
    return this.http.post(this.baseURL + '/authenticate/changepassword', data);
  }
  public GetAllAuditDetails() {
    return this.baseURL + '/audit/getallauditdetails';
  }
  public mfaverification(data) {
    return this.http.post(this.baseURL + '/authenticate/verifysmsmfa', data);
  }
  public getVerificationCode(username: string) {
    return this.http.post(this.baseURL + '/authenticate/forgotpassword', { 'userName': username });
  }
  public forgotpasswordsubmit(username: string, verificationCode: string, password: string) {
    console.log("password", password)
    return this.http.post(this.baseURL + '/authenticate/confirmpassword', { 'userName': username, 'verificationCode': verificationCode, 'password': password });
  }
  public getCustomers() {
    return this.http.post(this.baseURL + '/customer/list', {});
  }
  public getEmailTemplatesList() {
    return this.http.get(this.baseURL + '/tenant/getemailtemplates', {});
  }
  public getRoles() {
    return this.http.get(this.baseURL + '/authenticate/getroles', {});
  }
  public getDepartmentsByOrganization(custExtId, tenantID) {
    return this.http.post(this.baseURL + '/department/listbyorg', { 'custExtId': custExtId ,'tenantID':tenantID});
  }
  public getUserByUserName(userName: string) {
    return this.http.post(this.baseURL + '/authenticate/getuserconfig', { 'userName': userName });
  }
  public getUsersList(data) {
    return this.http.post(this.baseURL + "/authenticate/getuserslist", data);
  }
  public deleteJob(jobId: string) {
    return this.http.post(this.baseURL + '/job/jobdelete', { 'jobId': jobId, 'jobStatus': true });
  }
  public sendsrjobtonsw(jobId: string) {
    return this.http.post(this.baseURL + '/job/sendsrjobtoediting', { 'jobId': jobId })
  }
  public AWSLog(type: string, message: string) {
    var dateTimecon = new Date();
    var dateTime = this.datePipe.transform(dateTimecon, 'yyyy-MM-dd HH:mm:ss')
    this.logModel = new LoggingModel();
    this.logModel.type = type;
    this.logModel.message = message;
    this.logModel.dateTime = dateTime;
    this.logModel.status = "";
    return this.http.post(this.baseURL + '/logging/log', this.logModel);
  }
  public getfailedMessage(jobId: string) {
    return this.http.post(this.baseURL + '/job/getjobfailedmessage', { 'jobId': jobId });
  }
  public getReUploadAudiofileURL(jobId: string, type: string, audiofilename: string) {
    return this.http.post(this.baseURL + '/job/getaudiouploadurl', { 'jobId': jobId, 'type': type, 'audiofilename': audiofilename });
  }
  public replaceAudioInNSC(jobId: string, jobStatus: string, type: string, FileName: string) {
    return this.http.post(this.baseURL + '/job/replaceaudio', { 'jobId': jobId, 'jobStatus': jobStatus, 'type': type, 'FileName': FileName });
  }
  public replaceTranscriptInNSC(jobId: string, jobStatus: string, type: string, FileName: string) {
    return this.http.post(this.baseURL + '/job/replacetranscript', { 'jobId': jobId, 'jobStatus': jobStatus, 'type': type, 'FileName': FileName });
  }
  public resubmitJobs(jobIds: string) {
    return this.http.post(this.baseURL + '/job/resubmitjobstonsw', { 'jobId': jobIds });
  }
  public getjobswithfilterurl() {
    return this.baseURL + '/job/jobswithfilter';
  }
  public getorgswithfilterurl() {
    return this.baseURL + '/customer/organizationsbyfilter';
  }
  public getuserswithfilterurl() {
    return this.baseURL + '/authenticate/userswithfilter';
  }
  public getdepswithfilterurl() {
    return this.baseURL + '/department/departmentsbyfilter';
  }
  public MergeAudioFilesToNSW(jobId: string, bucketPath: string, filenames: object) {
    return this.http.post(this.baseURL + '/job/mergeaudiosandsendtonsw', { 'jobId': jobId, 'bucketPath': bucketPath, 'filenames': filenames });
  }
  public getAudioData(nsJobId: Number, ncJobId: string) {
    return this.http.post(this.baseURL + '/job/getaudiodata', { 'nsJobId': nsJobId, 'ncJobId': ncJobId });
  }
  public getEditorMediaURL(nsJobId: Number, ncJobId: string) {
    return this.http.post(this.baseURL + '/job/geteditormediaurl', { 'nsJobId': nsJobId, 'ncJobId': ncJobId });
  }
  public getDepartmentJobTypeValues(departmentId) {
    return this.http.post(this.baseURL + '/jobtypes/list', { 'departmentId': departmentId })
  }
  public getOutputFileNameList() {
    return this.http.post(this.baseURL + '/customer/getoutputfilenamesourcelist', {});
  }
   public updateUserJobColumns(userName: string, Id: Number, jobColumnsList: string, jobColumnsOrder: string,dateRange:string,customStartdate:string,customEnddate:string) {
   return this.http.post(this.baseURL + '/authenticate/updateuserprofile', { 'jobColumnsList': jobColumnsList, 'userName': userName, 'Id': Id, 'jobColumnsOrder': jobColumnsOrder,'dateRange':dateRange,'customStartdate':customStartdate,'customEnddate':customEnddate });
  }
  public JobHoldOrRemove( JobId: Number,Onhold:boolean ,Comment:string,JobReleaseHoldStateEnum:number) {
    console.log("JobHoldOrRemove starts::");
    
   return this.http.post(this.baseURL + '/job/holdorremovejob', {'jobId' : JobId ,'onhold':Onhold,'comment': Comment,'jobReleaseHoldStateEnum': JobReleaseHoldStateEnum});
  }
  public getZipFileUploadURL(fileName: string) {
    return this.http.post(this.baseURL + '/job/getzipuploadurl', { 'type': fileName });
  }
  public getZipFileDownloadURL(jobId: string) {
    return this.http.post(this.baseURL + '/job/getzipdownloadurl', { 'jobId': jobId });
  }
  public EnableOrDisableUser(userName: string, isActive: boolean) {
    this.userStatusModel = new userStatusModel();
    this.userStatusModel.userName = userName;
    this.userStatusModel.isActive = isActive;
    this.userStatusModel.status = "";
    return this.http.post(this.baseURL + '/authenticate/enableuser', this.userStatusModel);
  }
  createorganization(org: OrganizationsDto, callback: CognitoCallback) {
    this.http.post(this.baseURL + '/customer/createorganization', org).subscribe(
      data => {
        callback.cognitoCallback('Organization created successfully', null);
      },
      (error) => {
        callback.cognitoCallback('Unable to update organization', error);
      });
  }
  updateorganization(org: OrganizationsDto, callback: CognitoCallback) {
    this.http.post(this.baseURL + '/customer/editorganization', org).subscribe(
      data => {
        callback.cognitoCallback('Organization updated successfully', null);
      },
      (error) => {
        callback.cognitoCallback('Unable to create organization', error);
      });
  }
  createdepartment(dept: DepartmentsDto, callback: CognitoCallback) {
    this.http.post(this.baseURL + '/department/createdepartment', dept).subscribe(
      data => {
        callback.cognitoCallback('Department created successfully', null);
      },
      (error) => {
        callback.cognitoCallback('Unable to create department', error);
      });
  }
  updatedepartment(dept: DepartmentsDto, callback: CognitoCallback) {
    console.log("updatedepartment: ", dept);
    this.http.post(this.baseURL + '/department/editdepartment', dept).subscribe(
      data => {
        console.log("result: ", data);
        callback.cognitoCallback('Department updated successfully', null);
      },
      (error) => {
        callback.cognitoCallback('Unable to update department', error);
      });
  }
  createtenant(tenant: TenantsDto, callback: CognitoCallback) {
    this.http.post(this.baseURL + '/tenant/createtenant', tenant).subscribe(
      data => {
        callback.cognitoCallback('Tenant created successfully', null);
      },
      (error) => {
        callback.cognitoCallback('Unable to create Tenant', error);
      });
  }
  updatetenant(tenant: TenantsDto, callback: CognitoCallback) {
    this.http.post(this.baseURL + '/tenant/edittenant', tenant).subscribe(
      data => {
        callback.cognitoCallback('Tenant updated successfully', null);
        localStorage.setItem('CustomerNotesEnable', tenant.isCustomerNotesEnable.toString());
      },
      (error) => {
        callback.cognitoCallback('Unable to update Tenant', error);
      });
  }
  public gettenantswithfilterurl() {
    return this.baseURL + '/tenant/listtenants';
  }
  public getTenants() {
    return this.http.get(this.baseURL + '/tenant/list');
  }
  public getFormList() {
    return this.baseURL + '/formtypes/formtypefilterlist';
  }
  public createPassword(connector, callback: CognitoCallback) {
    this.http.post(this.baseURL + '/connectors/connectorscreate', connector).subscribe(
      data => {
        console.log("createPassword:::", data['success']);
        if (data['success'] == true) {
          callback.cognitoCallback('Connector created successfully', null);
        }
        else {
          callback.cognitoCallback(data['message'], null);
        }

      },
      (error) => {
        callback.cognitoCallback(error.error.message ? error.error.message : 'Unable to create Connector', error);
      });
  }
  public updatePassword(connector, callback: CognitoCallback) {
    this.http.post(this.baseURL + '/connectors/connectorsupdate', connector).subscribe(
      data => {
        console.log("updatePassword:::", data);
        if (data['success'] == true) {
          callback.cognitoCallback('Connector updated successfully', null);
        }
        else {
          callback.cognitoCallback(data['message'], null);
        }
      },
      (error) => {
        callback.cognitoCallback(error.error.message ? error.error.message : 'Unable to create Connector', error);
      });
  }
  public getconswithfilterurl() {
    return this.baseURL + '/connectors/getfilterconnectorslist';
  }
  public updateUserMFA(data, callback: CognitoCallback) {
    console.log("backend");
    return this.http.post(this.baseURL + '/authenticate/updatemfa', data).subscribe(
      data => {
        console.log("pass", data);
        callback.cognitoCallback('MFA updated successfully', null);
      },
      (error) => {
        console.log("fail");
        callback.cognitoCallback('Unable to update MFA', error);
      });
  }
  public syncOrgDept() {
    return this.http.post(this.baseURL + '/customer/getnsworgdept', {});
  }
  public getsupportdetailsbyusername(username: string) {
    return this.http.post(this.baseURL + '/authenticate/getsupportdetails', { 'UserName': username });
  }
  public IdpUserAuthnticate(code: string) {
    return this.http.post(this.baseURL + '/authenticate/idplogin', { 'code': code });
  }
  public getIdPUrlbydomainname(domainname: string) {
    return this.http.post(this.baseURL + '/authenticate/idpbydomain', { 'domainname': domainname });
  }
  public createauthor(formData: object) {
    return this.http.post(this.baseURL + '/authors/creatensauthor', formData);
  }
  public adminnsjobsend(jobId: string) {
    return this.http.post(this.baseURL + '/job/adminnsjobsend', { 'jobId': jobId });
  }
  public UserPhoneNumberverification(data) {
    return this.http.post(this.baseURL + '/authenticate/verifyphonenumber', data);
  }
  public updateverifyskipcount(data) {
    return this.http.post(this.baseURL + '/authenticate/updateverifyskipcount', data);
  }
  public SendEstimationEmail(data) {
    return this.http.post(this.baseURL + '/job/sendestimationemail', data);
  }
  public getflratebylanguage(data) {
    return this.http.post(this.baseURL + '/job/getflratebylanguage', data);
  }
  setUserLoggedInForVerification(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
    var curpage = localStorage.getItem('page');
    if (curpage == 'nclanding') {
      localStorage.setItem('idpuser', 'true');
    }
    else if (curpage == 'login') {
      localStorage.setItem('idpuser', 'false');
    }
  }
  
}
