import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';

import { NgxSpinnerService } from 'ngx-spinner';
import { LoginResponse } from 'src/app/models/apiRequestModels';
import { ResponseMessage } from 'src/app/models/ResponseMessage';
import { BackendService } from 'src/app/services/backend.service';
import { environment } from 'src/environments/environment';
import { ForceChangePasswordComponent } from '../force-change-password/force-change-password.component';
import { MFAComponent } from '../mfa/mfa-otp.component';

declare var $: any;
@Component({
  selector: 'app-user-landing',
  templateUrl: './user-landing.component.html',
  styleUrls: ['./user-landing.component.css']
})
export class UserLandingComponent implements OnInit {

  code: string;
  test: Date = new Date();
  brandname = localStorage.getItem('brand');
  bgimage: string;
  private loginresponse: LoginResponse;
  private userConfig: any = null;
  returnUrl: string;
  errormessage = "";
  usernameGlobal = "";
  passwordGlobal = "";
  versionNumber = "";
  buildVersion = "";
  private nativeElement: Node;
  private sidebarVisible: boolean;
  private mfaloginresponse: LoginResponse;
  private toggleButton: any;
  private currentURL;
  isloginbtn = true;

  form = new FormGroup({
    userName: new FormControl(null, Validators.required)
  });

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    
    private backendService: BackendService,
    private spinner: NgxSpinnerService,
    private element: ElementRef,
    public dialog: MatDialog,

    @Inject(DOCUMENT) private document: Document) {
    this.activatedRoute.queryParams.subscribe(params => {
      var idpemail = localStorage.getItem('idpemail');
      console.log("idpemail: ", idpemail)
      this.code = params['code'];
      console.log("this.code: ", this.code);
      this.currentURL = this.document.location.href;
      console.log("Landing Page URL ", this.currentURL);
      var error_code = params['error_description'];
      if (error_code != undefined) {
        if (error_code.includes("Invalid")) {
          console.log("error_code: contains Invalid");
          alert("Your organization's identify provider is not sending valid department or role information.  Please contact support.");
        }
      }
      if (this.code == undefined) {
        if (idpemail) {
          this.form.controls.userName.setValue(idpemail);
          this.usernameGlobal = idpemail;
          this.isloginbtn = false;
          if (this.validemail) {
            this.form.invalid;
          }
          this.getdomain();

        }
      }

      console.log(this.code); // Print the parameter to the console.
      this.nativeElement = element.nativeElement;
      this.sidebarVisible = false;
      this.bgimage = './assets/img/' + this.brandname + '/login_bg.png'

    });
  }
  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
  notificationTemplate = 'success';
  ngOnInit() {
    this.versionNumber = environment.Version;
    this.buildVersion = environment.BuildVersion;
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    body.classList.add('off-canvas-sidebar');
    const card = document.getElementsByClassName('card')[0];
    setTimeout(function () {
      // after 1000 ms we add the class animated to the login/register card
      card.classList.remove('card-hidden');
    }, 700);
    if (this.code) {
      var idpemail = localStorage.getItem('idpemail');
      this.form.controls.userName.setValue(idpemail);
      this.isloginbtn = false;
      this.spinner.show();
      this.getuserdetails();
    }
  }
  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
    body.classList.remove('off-canvas-sidebar');
  }
  setReturnUrl() {

    if (this.backendService.getJSONResponse(this.userConfig).userManagementDto.isTNC == 'false') {
      this.returnUrl = '/termconditions';
    }
    else
      this.returnUrl = '/admin';
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ForceChangePasswordComponent, {
      width: '35%',
      data: { "currentpassword": this.passwordGlobal, "currentusername": this.usernameGlobal },
      disableClose: true
    });
  }
  sendNotification(message, messageType) {
    this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
    this.msgNotification.template(messageType);
    this.msgNotification.open();
  }
  openMFADialog(): void {
    const dialogRef = this.dialog.open(MFAComponent, {
      width: '35%',
      data: { "currentpassword": this.passwordGlobal, "currentusername": this.usernameGlobal, "loginresponse": this.loginresponse },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(mfaResult => {

      if (mfaResult.event == 'MFA') {
        if (mfaResult != null) {
          this.mfaloginresponse = mfaResult.data;
          this.setloggedinUser(mfaResult)
          this.spinner.hide();
        }
      }

    });
  }
  async setloggedinUser(result: any) {
    localStorage.removeItem('token');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user_attribute');
    localStorage.setItem('token', result.data.token);
    localStorage.setItem('accessToken', result.data.accessToken);
    localStorage.setItem('user_attribute', result.data.userName);
    localStorage.removeItem('user_isadmin');
    this.userConfig = this.backendService.getJSONResponse(result.data);
    localStorage.setItem('user_isadmin', result.data.userManagementDto.isAdmin);
    localStorage.setItem('id', result.data.userManagementDto.id);
    localStorage.setItem('userName', result.data.userManagementDto.userName);
    localStorage.setItem('user_roleName', result.data.userManagementDto.roleName);
    this.setReturnUrl();
    this.backendService.setUserLoggedIn(true);
    this.router.navigateByUrl(this.returnUrl);
  }
  openChangePasswordDialog(): void {
    const dialogRef = this.dialog.open(ForceChangePasswordComponent, {
      width: '35%',
      data: { "currentpassword": this.passwordGlobal, "currentusername": this.usernameGlobal },
      disableClose: true
    });
  }
  get f() { return this.form.controls; }
  isValid(controlName, error) {
    return this.form && this.form.controls[controlName].hasError(error);
  }

  validemail() {
    var mailformat = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,9}$";
    if (this.usernameGlobal.match(mailformat)) {
      alert("Valid email address!");
      return true;
    }
    else {
      alert("You have entered an invalid email address!");
      return false;
    }
  }
  getdomain() {

    this.usernameGlobal = this.form.controls.userName.value;
    if (this.usernameGlobal == "" || this.usernameGlobal == null) {
      this.sendNotification("Please enter user e-mail", 'error');
    }
    else if (this.validemail) {
      this.form.invalid;
    }
    if (this.form.valid) {
      this.spinner.show();
      console.log("useremail: ", this.usernameGlobal)
      localStorage.setItem('idpemail', this.usernameGlobal);
      localStorage.setItem('idpuser', "true");
      var userdomain = this.usernameGlobal.split("@")
      if (userdomain.length > 1) {
        var currentdomain = userdomain[1];
        console.log("currentdomain: ", currentdomain);
        if (currentdomain == 'undefined') {
          this.sendNotification("Please enter valid e-mail", 'error');
          this.isloginbtn = true;
          this.spinner.hide();
        }
        else {
          this.backendService.getIdPUrlbydomainname(currentdomain).subscribe(
            res => {
              console.log("IDP_result_res...", res);
              const result = this.backendService.getJSONResponse(res);
              if (result != null) {
                console.log("IDP_result_result...", result);
                this.loginresponse = JSON.parse(JSON.stringify(result.data))
                if (result.success == true) {
                  console.log("IDP_result.data...", result.data);
                  window.open(result.data.idpURL, "_self")
                }
                else {
                  this.sendNotification("Please check e-mail, unable to find SSO details", 'error');
                  this.isloginbtn = true;
                  this.spinner.hide();
                }
              }
            },
            err => {
              console.log("error result :::", err.error.message)
              var errorresult = err.error.message;
              this.sendNotification(errorresult, 'error');
              this.spinner.hide();

            });
        }
      }
      else {
        this.sendNotification("Please enter valied e-mail", 'error');
        this.isloginbtn = true;
        this.spinner.hide();
      }
    }
  }
  getuserdetails() {
    this.backendService.IdpUserAuthnticate(this.code).subscribe(
      res => {
        console.log("authnticate result...", res);
        const result = this.backendService.getJSONResponse(res);
        if (result != null) {
          console.log("authnticate result...", result);
          this.loginresponse = JSON.parse(JSON.stringify(result.data))
          if (result.data.isSuccess == true) {
            if (result.data.userStatus == ResponseMessage.authenticated) {
              localStorage.removeItem('token');
              localStorage.removeItem('accessToken');
              localStorage.removeItem('user_attribute');
              localStorage.setItem('token', result.data.token);
              localStorage.setItem('accessToken', result.data.accessToken);
              localStorage.setItem('user_attribute', result.data.userName);
              localStorage.removeItem('user_isadmin');
              this.userConfig = this.backendService.getJSONResponse(result.data);
              localStorage.setItem('user_isadmin', result.data.userManagementDto.isAdmin);
              localStorage.setItem('id', result.data.userManagementDto.id);
              localStorage.setItem('userName', result.data.userManagementDto.userName);
              localStorage.setItem('user_roleName', result.data.userManagementDto.roleName);
              localStorage.setItem('user_jobColumnsList', result.data.userManagementDto.jobColumnsList);
              localStorage.setItem('user_jobColumnsOrder', result.data.userManagementDto.jobColumnsOrder);
              localStorage.setItem('user_customStartdate', result.data.userManagementDto.customStartdate);
              localStorage.setItem('user_customEnddate', result.data.userManagementDto.customEnddate);
              localStorage.setItem('user_dateRange', result.data.userManagementDto.dateRange);
              localStorage.setItem('tenantId', result.data.userManagementDto.tenantId);
              localStorage.setItem('user_authorExtId', result.data.userManagementDto.authorExtId);
              localStorage.setItem('estimationMessage',result.data.userManagementDto.estimationMessage);
              localStorage.setItem('isEstimateEnable',result.data.userManagementDto.isEstimateEnable);
              localStorage.setItem('isForeignLanguage',result.data.userManagementDto.isForeignLanguage);
              localStorage.setItem('departments', result.data.userManagementDto.departments);
              localStorage.setItem('CustomerNotesEnable', result.data.userManagementDto.isCustomerNotesEnable);
              this.setReturnUrl();
              this.backendService.setUserLoggedIn(true);
              this.router.navigateByUrl(this.returnUrl);
            }
          }
          else {
            if (result.data.userStatus == ResponseMessage.NEW_PASSWORD_REQUIRED) {
              this.spinner.hide();
              this.openDialog();
            }
            if (result.data.userStatus == ResponseMessage.USER_IS_DISABLED) {
              this.errormessage = ResponseMessage.USER_IS_DISABLED;
              this.sendNotification(this.errormessage, 'error');
              this.spinner.hide();

            }
            if (result.data.userStatus == ResponseMessage.SMS_MFA) {
              this.spinner.hide();
              this.openMFADialog();
            }
            if (result.data.userStatus == ResponseMessage.INCORRECT_USERNAME_PASSWORD) {
              this.errormessage = "Invalid Username/Password."
              this.sendNotification(this.errormessage, 'error');
              this.spinner.hide();
            }
            if (result.data.userStatus == ResponseMessage.EMAIL_ADDRESS_NOT_CONFIRMED) {
              this.errormessage = ResponseMessage.EMAIL_ADDRESS_NOT_CONFIRMED
              this.sendNotification(this.errormessage, 'error');
              this.spinner.hide();
            }
            if (result.data.userStatus == ResponseMessage.USER_NOT_FOUND) {
              this.errormessage = "User not found"
              this.sendNotification(this.errormessage, 'error');
              this.spinner.hide();
            }
            if (result.data.userStatus == ResponseMessage.CODE_EXPIRED) {
              this.errormessage = "SESSION CODE EXPIRED"
              this.sendNotification(this.errormessage, 'error');
              this.spinner.hide();
            }
            if (result.data.userStatus == ResponseMessage.PASSWORD_EXPIRED) {
              this.errormessage = "PASSWORD_EXPIRED."
              this.sendNotification(this.errormessage, 'error');
              this.spinner.hide();
              this.openChangePasswordDialog();
            }
          }

        }


      },
      err => {
        var errorresult = err.error.message;
        if (errorresult == "invalidUsernameorPassword") {
          this.errormessage = "Invalid Username/Password."
          this.sendNotification(this.errormessage, 'error');
        }
        if (errorresult == "newPasswordRequired") {
          this.openDialog();
        }
        this.spinner.hide();

      }
    )

  }
  today: number = Date.now();
  ViqIdplogin() {
    console.log("ViqIdplogin");
    window.open('https://dev-viq-test-auth.auth.ca-central-1.amazoncognito.com/oauth2/authorize?identity_provider=VIQ-Azure-AD-Test&redirect_uri=https://devnsconnect.aiassist.ai/nclanding&response_type=CODE&client_id=1inag0vhqu04sv5l74gj65kta8&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile', "_self");
  }

}
